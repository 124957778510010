import store from "@/store";
export const dataSetCodeConverter = {
    methods: {
        krToEn(kr : String) {
            const en = store.getters.getDataSet.find((obj : any) => obj.dataSetRepresentativeName === kr).dataSet;
            return en
        },
        enToKr(en :String) {
            const kr = store.getters.getDataSet.find((obj : any) => obj.dataSet === en).dataSet;
            return kr
        }
    },
}
