<template>
  <div style="">
    <v-container style="max-width: none">
      <v-row>
        <v-col cols="12" style="padding: 0">
          <v-col cols="12">
            <v-card outlined tile style="padding: 6px;">
              <Datatable
              ></Datatable>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<!--<template>-->
<!--  <div>-->
<!--    <ul v-for="(item, n) in this.apiData" :key="n">-->
<!--      <li>{{ item }}</li>-->
<!--    </ul>-->
<!--  </div>-->
<!--</template>-->

<script>
import Datatable from "@/components/explore_content/third_page/Datatable";

export default {
  name: "ThirdCate",

  components: { Datatable },
  component: {
    Datatable
  },
  data() {
    return {

    };
  },


  beforeCreate() {
    if (
      !this.$route.query.firstCate ||
      !this.$route.query.secondCate ||
      !this.$route.query.thirdCate ||
      !this.$route.query.section
    ) {
      this.$router.push({ name: "Index" }).catch(err => {});
    }
  }
};
</script>

<style scoped></style>
